import { Button, Divider, Form, Input, Space, Spin, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import Notification from '../../components/Notification';
import Instance from '../../service/service';

const { Title } = Typography;

const getRealyieldLink = async () => {
    const data = await Instance.get('provide-data/getRealyieldLink');
    return data;
}

const Realyield = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        const response = getRealyieldLink();
        response.then(res => {
            if (res.status !== 200) {
                setData(null);
            } else {
                setData(res.data);
            }
            setLoading(false);
        })
    }, [])

    const onSave = () => {
        setLoading(true);
        form.validateFields().then(async (value) => {
            const response = await Instance.post('provide-data/updateRealyieldLink', JSON.stringify(value));
            if (response.status === 200) {
                setData(value);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setLoading(false);
        }).catch((reason) => { setLoading(false); return; })
    };

    const onReset = () => {
        form.setFieldsValue(data);
    };

    return <>
        <Spin spinning={loading}>
            <div>
                <Title level={4}>Link Realyield</Title>
                <Divider style={{ padding: '5px 5px' }} />
                {data ?
                    <Form form={form}>
                        <Form.Item
                            name="url"
                            label="Realyield URL"
                            initialValue={data.url}
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill Realyield URL'
                                },
                                {
                                    type: 'url',
                                    message: 'The input is not valid URL',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'center', padding: 5, margin: 5 }}>
                                <Space>
                                    <Button type="primary" onClick={() => onSave()}>
                                        บันทึก
                                    </Button>
                                    <Button type="default" onClick={() => onReset()}>
                                        Reset
                                    </Button>
                                </Space>
                            </div>
                        </Form.Item>
                    </Form>
                    : <></>}
            </div>
        </Spin>
    </>
}

export default Realyield;