import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";
import './news.css';

const { Title } = Typography;

const getArticle = async () => {
    const data = await Instance.get('provide-data/getArticle');
    return data;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const News = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [articles, setArticles] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);

    useEffect(() => {
        const response = getArticle();
        response.then(res => {
            if (res.status !== 200) {
                setArticles(null);
            } else {
                setArticles(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    const onSave = () => {
        setLoadingForm(true);
        form.validateFields().then(async (value) => {
            value.seq = selectedArticle.seq;
            const response = await Instance.post('provide-data/updateArticle', JSON.stringify(value));
            if (response.status === 200) {
                const index = articles.findIndex((article) => article.seq === value.seq);
                const newArticles = articles;
                newArticles[index] = value;
                setArticles(newArticles);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            return;
        });
    };

    const onEdit = (selectedArticle, idx) => {
        selectedArticle.seq = idx + 1;
        form.setFieldsValue({ title: selectedArticle.title, description: selectedArticle.description })
        setSelectedArticle(selectedArticle);
        setVisible(true);
    }

    return (
        <>
            <Modal
                title="Update data"
                style={{ top: 25 }}
                visible={visible}
                onOk={() => onSave()}
                onCancel={() => setVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={form}>
                        <Form.Item
                            name="title"
                            label="Title"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill title'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill description'
                                },
                            ]}
                        >
                            <Input.TextArea rows={10} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการแผนการเทรด</Title>
                    <Row gutter={[8, 8]}>
                        {
                            articles ? articles.map((article, idx) => {
                                return <Col key={idx} span={32} xs={32} sm={32} md={12} lg={12}>
                                    <Card
                                        bodyStyle={{ height: 180 }}
                                        key={idx}
                                        title={<b>{article.title}</b>}
                                        bordered={true}
                                        actions={[
                                            <Button type="primary" block onClick={() => onEdit(article, idx)}><EditOutlined key="edit" /></Button>
                                        ]}
                                    >
                                        <p>{article.description}</p>
                                    </Card>
                                </Col>
                            }) : <></>
                        }
                    </Row>
                </div>
            </Spin>
        </>
    )
}

export default News;