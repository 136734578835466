import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Spin, Table, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";

const { Title } = Typography;
const { Option } = Select;

const getMembers = async () => {
    const data = await Instance.get('member');
    return data;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const memberColumns = (onEditMember, onConfirmDeleteMember, filterType) => {
    const columns = [
        {
            title: 'NO',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 70,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'ชื่อ',
            dataIndex: 'firstname',
            key: 'firstname',
            fixed: 'left',
            // width: 150,
        },
        {
            title: 'นามสกุล',
            dataIndex: 'lastname',
            key: 'lastname',
            fixed: 'left',
            // width: 150,
        },
        {
            title: 'อีเมลล์',
            dataIndex: 'email',
            key: 'email',
            width: 300,
        },
        {
            title: 'ตำแหน่ง',
            dataIndex: 'memberRole',
            key: 'memberRole',
            render: (text, record, index) => {
                if (text === 'admin') return <Tag color={'#108ee9'} key={text}>Admin</Tag>;
                else if (text === 'member') return <Tag color={'#f50'} key={text}>Member</Tag>;

                return <Tag color={'red'} key={text}>{text}</Tag>
            }
        },
        {
            title: 'ยืนยันอีเมลล์',
            dataIndex: 'verifyEmailStatus',
            key: 'verifyEmailStatus',
            render: (text, record, index) => {
                return <>{text === 'active' ? <Tag color={'#87d068'} key={text}>ยืนยัน</Tag> : <Tag color={'#f50'} key={text}>รอยืนยัน</Tag>}</>
            }
        },
        {
            title: 'วันหมดอายุ',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            render: (text, record, index) => {
                return moment(record.endPremiumDate).format('YYYY-MM-DD');
            }
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditMember(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteMember(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    if (filterType === 'search') {
        const searchColumns = columns.filter(v => {
            return v.dataIndex !== 'total';
        });
        
        return searchColumns;
    }
    return columns;
}


const Member = () => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editMemberVisible, setEditMemberVisible] = useState(false);
    const [deleteMemberVisible, setDeleteMemberVisible] = useState(false);
    const [members, setMembers] = useState(null);
    const [membersTemp, setMembersTemp] = useState(null);
    const [memberSelected, setMemberSelected] = useState(null);
    const [filterType, setFilterType] = useState('search');
    const [roleSelected, setRoleSelected] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    const onSearchMember = async () => {
        setFilterType('search');
        if (firstname === '' && lastname === '' && email === '') return;
        setLoading(true);
        const response = await Instance.get(`/member/?${(firstname !== "" && `filter=firstname||$starts||${firstname}`)}&${(lastname !== "" && `filter=lastname||$starts||${lastname}`)}&${(email !== "" && `filter=email||$starts||${email}`)}`);

        if (response.status === 200) {
            setMembers(response.data.data);
        } else {
            Notification('Something wrong. Please try again.', 'error');
        }
        setLoading(false);
    }

    const onReset = () => {
        setLoading(true);
        setFirstname('');
        setLastname('');
        setEmail('');
        setRoleSelected('');
        setFilterType('search');
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }

    const onEditMember = (member) => {
        form.resetFields();
        if (member.registerPremiumDate) member.registerPremiumDate = moment(member.registerPremiumDate);
        if (member.endPremiumDate) member.endPremiumDate = moment(member.endPremiumDate);
        member.password = "";
        form.setFieldsValue(member);
        setRoleSelected(member.memberRole);
        setMemberSelected(member);
        setEditMemberVisible(true);
    }

    const onConfirmSave = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSave()
        });
    }

    const onSave = () => {
        setLoadingForm(true);
        form.validateFields().then(async (value) => {
            const data = form.getFieldValue();
            const temp = data;
            const id = data.id;
            if (data.password === "") delete data.password;
            delete data.id;
            const response = await Instance.patch('member/' + id, JSON.stringify(data));
            if (response.status === 200) {
                const index = members.findIndex((member) => member.id === id);
                const newMembers = members;
                temp.id = id;
                newMembers[index] = temp;
                setMembers(newMembers);
                setMembersTemp(newMembers);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update data. Please try again.', 'error');
            }
            setEditMemberVisible(false);
            setLoadingForm(false);
        });
    }

    const onConfirmDeleteMember = (member) => {
        setMemberSelected(member);
        setDeleteMemberVisible(true);
    }

    const onDeleteMember = async () => {
        setLoadingForm(true);
        const response = await Instance.delete('member/' + memberSelected.id);
        if (response.status === 200) {
            const newMembers = members.filter((member) => member.id !== memberSelected.id);
            setMembers(newMembers);
            setMembersTemp(newMembers);
            Notification('Delete member success', 'success');
        } else {
            Notification('Can not delete member. Please try again.', 'error');
        }
        setDeleteMemberVisible(false);
        setLoadingForm(false);
    }

    const onExpireFilter = () => {
        setFilterType('expire');
        setLoading(true);

        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                const data = res.data.data;
                const filterMembers = data.filter(member => {
                    const date = new Date();
                    const currentDate = moment(date, 'YYYY-MM-DD');
                    const endDate = moment(member.endPremiumDate, 'YYYY-MM-DD');
        
                    const total = endDate.diff(currentDate, 'days');
                    if (total <= 5) {
                        return member;
                    }
                });
                setMembers(filterMembers);
                setMembersTemp(filterMembers);
            }
            setLoading(false);
        })
    }

    const onChangeRole = (e) => {
        setRoleSelected(e);
    }

    return (
        <>
            <Modal
                title={<Title level={5}>แก้ไขข้อมูลสมาชิก</Title>}
                style={{ top: 25 }}
                visible={editMemberVisible}
                onOk={() => onConfirmSave()}
                onCancel={() => setEditMemberVisible(false)}
                width={1000}
            >
                {memberSelected ?
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={form}>
                        <Form.Item
                            name="firstname"
                            label="ชื่อ"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill firstname'
                                },
                            ]}
                        >
                            <Input placeholder="ระบุชื่อ" />
                        </Form.Item>
                        <Form.Item
                            name="lastname"
                            label="นามสกุล"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill lastname'
                                },
                            ]}
                        >
                            <Input placeholder="ระบุนามสกุล" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="อีเมลล์"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill email'
                                },
                            ]}
                        >
                            <Input placeholder="ระบุอีเมลล์" />
                        </Form.Item>
                        <Form.Item name="memberRole" label="ตำแหน่ง" rules={[{ required: true }]}>
                            <Select
                                placeholder="เลือกตำแหน่ง"
                                onChange={(e) => onChangeRole(e)}
                            >
                                <Option value="admin">ผู้ดูแล (Admin)</Option>
                                <Option value="member">สมาชิก (Member)</Option>
                                <Option value="monthlyPremium">สมาชิกรายเดือน (Monthly Premium)</Option>
                                <Option value="yearlyPremium">สมาชิกรายปี (Yearly Premium)</Option>
                                <Option value="custom">กำหนดเอง (Custom)</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="verifyEmailStatus" label="สถานะยืนยันอีเมลล์" rules={[{ required: true }]}>
                            <Select
                                placeholder="เลือกสถานะ"
                            >
                                <Option value="active">ยืนยัน</Option>
                                <Option value="inactive">รอยืนยัน</Option>
                            </Select>
                        </Form.Item>
                        {roleSelected === 'yearlyPremium' || roleSelected === 'monthlyPremium' || roleSelected === 'custom' ? 
                        <Form.Item name="registerPremiumDate" label="วันสมัคร Premium" rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder={'Register Date'} />
                        </Form.Item> : <></> }
                        {roleSelected === 'custom' ? 
                        <Form.Item name="endPremiumDate" label="วันสิ้นสุด Premium" rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder={'End Premium Date'} />
                        </Form.Item> : <></> }
                        <Form.Item
                            name="password"
                            label="รหัสผ่าน"
                            val
                        >
                            <Input type={'password'} placeholder="กรอกรหัสผ่านเมื่อต้องการเปลี่ยน" />
                        </Form.Item>
                    </Form>
                </Spin>
                : <></> }
            </Modal>
            <Modal
                title={<Title level={5}>ลบสมาชิก</Title>}
                style={{ top: 25 }}
                visible={deleteMemberVisible}
                onOk={() => onDeleteMember()}
                onCancel={() => setDeleteMemberVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Title level={5}>ต้องการลบสมาชิกชื่อ {memberSelected ? memberSelected.firstname + ' ' + memberSelected.lastname : ''} ใช่หรือไม่?</Title>
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการสมาชิก</Title>
                </div>
                <Row gutter={[8, 8]}>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={firstname} placeholder="ชื่อ" onChange={(e) => setFirstname(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={lastname} placeholder="นามสกุล" onChange={(e) => setLastname(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={email} placeholder="อีเมลล์" onChange={(e) => setEmail(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Space>
                            <Button type="primary" onClick={() => onSearchMember()}>ค้นหา</Button>
                            <Button type="primary" danger onClick={() => onExpireFilter()}>ใกล้หมดอายุ</Button>
                            <Button type="default" onClick={() => onReset()}>Reset</Button></Space></div>
                    </Col>
                </Row>
                {members ?
                    <Table size="small" columns={memberColumns(onEditMember, onConfirmDeleteMember, filterType)} dataSource={[...members]} scroll={{ x: 1200 }} pagination={{ defaultPageSize: 50, position: ['bottomRight', 'topRight'] }} /> : <></>}
            </Spin>
        </>
    )
}

export default Member;