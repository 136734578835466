import React, { Component } from "react";
import { Layout, Menu, Button, Dropdown, Card, Avatar, Spin } from 'antd';
import { Link, Switch, Route, BrowserRouter } from 'react-router-dom'
import { MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons'
import routes from '../../routes'
import './dashboard.css'

const { Header, Sider, Content } = Layout;

const { Meta } = Card;

export default class Dashboard extends Component {

    constructor(props) {
        super(props)

        if (!localStorage.getItem('token')) {
            props.history.push('/signin');
        }

        this.state = {
            collapsed: false,
            loading: false,
            loadingProfile: false,
            profile: JSON.parse(localStorage.getItem('profile')),
            role: '1',
            visibleEditProfileForm: false,
        };
    }

    renderMenu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Card
                        style={{ width: 300 }}
                        actions={[
                            <LogoutOutlined type="logout" key="logout" onClick={this.logout} />,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                            title={this.state.profile != null ? this.state.profile.firstName + " " + this.state.profile.lastName : ""}
                            description={this.state.profile ? 'Admin' : "Admin"}
                        />
                    </Card>
                </Menu.Item>
            </Menu>
        )
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    logout = async () => {

        this.setState({
            loading: true
        })

        localStorage.removeItem('token');
        localStorage.removeItem('profile');

        this.setState({
            loading: false
        })

        this.props.history.push('/signin');

    }

    render() {
        return (
            <BrowserRouter>
                <Spin spinning={this.state.loading}>
                    <div>
                        <Layout className="layout">
                            <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ height: '100vh' }}>
                                <div className="sider">Forex Monday</div>
                                <Menu theme="dark" mode="inline" defaultSelectedKeys={[this.state.role]}>
                                    {
                                        routes.map((route, idx) => {
                                            return <Menu.Item key={idx + 1}>
                                                <Link to={route.path} className="nav-text">
                                                    {route.icon}
                                                    <span>{route.nameTH}</span>
                                                </Link>
                                            </Menu.Item>
                                        })
                                    }
                                </Menu>
                            </Sider>
                            <Layout>
                                <Header style={{ padding: 0, backgroundColor: "white" }}>
                                    <MenuFoldOutlined
                                        className="trigger"
                                        style={{ paddingLeft: 22, fontSize: 20, color: 'grey' }}
                                        onClick={this.toggle}
                                    />

                                    <Dropdown overlay={this.renderMenu} placement="bottomLeft">
                                        <Button type="defualt" style={{ float: 'right', marginRight: 10, marginTop: 15 }}>โปรไฟล์</Button>
                                    </Dropdown>

                                </Header>
                                <Content className="content">
                                    <Switch>
                                        {
                                            routes.map((route, idx) => {
                                                return route.component
                                                    ? (<Route key={idx} path={route.path} exact={route.exact}
                                                        name={route.name} render={props => (<route.component {...props} />)} />)
                                                    : (null);
                                            })
                                        }
                                    </Switch>
                                </Content>
                            </Layout>
                        </Layout>
                    </div>
                </Spin>
            </BrowserRouter>
        );
    }
}