import { Button, Slider, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";

const { Title } = Typography;

const formatter = (value) => {
    return `${value}%`;
}

const getWeighting = async () => {
    const data = await Instance.get('provide-data/getWeighting');
    return data;
}

const Weighting = () => {
    const [loading, setLoading] = useState(true);
    const [percent, setPercent] = useState(null);
    const [percentTemp, setPercentTemp] = useState(true);
    const [sideway, setSideway] = useState(0);
    const [sidewayTemp, setSidewayTemp] = useState(0);

    useEffect(() => {
        const response = getWeighting();
        response.then(data => {
            if (data.status !== 200) {
                setPercent(null);
                setPercentTemp(null);
            } else {
                setPercent(data.data);
                setPercentTemp(data.data);
                setSideway(data.data.sidewayPercent);
                setSidewayTemp(data.data.sidewayPercent);
            }
            setLoading(false);
        })
    }, []);

    // const calculate = (percent, newValue, status) => {
    //     if (status === 'sideway') {
    //         const half = 100 - newValue;
    //         if (half % 2 === 0) {
    //             setPercentTemp({
    //                 sidewayPercent: newValue,
    //                 buyPercent: half / 2,
    //                 sellPercent: half / 2
    //             })
    //         } else {
    //             setPercentTemp({
    //                 sidewayPercent: newValue,
    //                 buyPercent: (half + 1) / 2,
    //                 sellPercent: (half - 1) / 2
    //             })
    //         }
    //     } else if (status === 'buy') {
    //         const sideway = percent.sidewayPercent;
    //         if (newValue <= sideway) {
    //             setPercentTemp({
    //                 sidewayPercent: sideway,
    //                 buyPercent: newValue,
    //                 sellPercent: 100 - (sideway + newValue)
    //             })
    //         } else {
    //             if (newValue + sideway >= 100) {
    //                 setPercentTemp({
    //                     sidewayPercent: sideway,
    //                     buyPercent: 100 - sideway,
    //                     sellPercent: 0
    //                 })
    //             } else {
    //                 setPercentTemp({
    //                     sidewayPercent: sideway,
    //                     buyPercent: newValue,
    //                     sellPercent: 100 - (newValue + sideway)
    //                 })
    //             }
    //         }
    //     } else if (status === 'sell') {
    //         const sideway = percent.sidewayPercent;
    //         if (newValue <= sideway) {
    //             setPercentTemp({
    //                 sidewayPercent: sideway,
    //                 buyPercent: 100 - (sideway + newValue),
    //                 sellPercent: newValue
    //             })
    //         } else {
    //             if (newValue + sideway >= 100) {
    //                 setPercentTemp({
    //                     sidewayPercent: sideway,
    //                     buyPercent: 0,
    //                     sellPercent: 100 - sideway
    //                 })
    //             } else {
    //                 setPercentTemp({
    //                     sidewayPercent: sideway,
    //                     buyPercent: 100 - (newValue + sideway),
    //                     sellPercent: newValue
    //                 })
    //             }
    //         }
    //     }
    // }

    const onSave = async () => {
        setLoading(true);
        const response = await Instance.post('provide-data/updateSideway', JSON.stringify({
            sidewayPercent: sidewayTemp
        }));
        if (response.status === 200) {
            setSideway(sidewayTemp);
            setSidewayTemp(sidewayTemp);
            Notification('Update data success', 'success');
        } else {
            setSideway(sideway);
            setSidewayTemp(sideway);
            Notification('Can not update date. Please try again.', 'error');
        }
        setLoading(false);
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="site-card-wrapper">
                    <Title level={4}>จัดการ WEIGHTING</Title>
                    <br />
                    {
                        percentTemp && percent ?
                            <>
                                <Title level={5}>Sideway Status ({sidewayTemp <= 0 ? "0%" : sidewayTemp + "%"})</Title>
                                <Slider defaultValue={sideway} value={sidewayTemp} tipFormatter={formatter} onChange={(newValue) => setSidewayTemp(newValue)} />

                                {/* <Title level={5}>Buy Status ({percentTemp.buyPercent <= 0 ? "0%" : percentTemp.buyPercent + "%"})</Title>
                                <Slider defaultValue={percent.buyPercent} value={percentTemp.buyPercent} tipFormatter={formatter} onChange={(newValue) => calculate(percentTemp, newValue, 'buy')} />

                                <Title level={5}>Sell Status ({percentTemp.sellPercent <= 0 ? "0%" : percentTemp.sellPercent + "%"})</Title>
                                <Slider defaultValue={percent.sellPercent} value={percentTemp.sellPercent} tipFormatter={formatter} onChange={(newValue) => calculate(percentTemp, newValue, 'sell')} /> */}
                            </> : <></>
                    }
                    <br />
                    <Space>
                        <Button type="primary" onClick={() => onSave()}>Save</Button>
                        <Button type="default" onClick={() => setSidewayTemp(sideway)}>Reset</Button>
                    </Space>
                </div>
            </Spin>
        </>
    )
}

export default Weighting;