import { Button, Col, Image, Input, Modal, Row, Space, Spin, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";

const { Title } = Typography;

const getMembers = async () => {
    const data = await Instance.get('approve-member?filter=approveStatus||$eq||waiting');
    return data;
}

const Approve = () => {
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [members, setMembers] = useState(null);
    const [memberSelected, setMemberSelected] = useState(null);
    const [membersTemp, setMembersTemp] = useState(null);
    const [confirmMemberVisible, setConfirmMemberVisible] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [imageSelected, setImageSelected] = useState(null);
    const [filterType, setFilterType] = useState('search');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    const previewImage = (member) => {
        setImageSelected(member.picture);
        setShowImage(true);
    }

    const columns = [
        {
            title: 'NO',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 70,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>ชื่อ - สกุล</div>,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text, record, index) => {
                return <>{record.firstName + " " + record.lastName}</>
            }
        },
        {
            title: 'อีเมลล์',
            dataIndex: 'email',
            key: 'email',
            width: 300,
        },
        {
            title: 'ธนาคาร',
            dataIndex: 'bank',
            key: 'bank',
            render: (text, record, index) => {
                return <>
                    {record.accountNumber + " (" + record.bankName + ")"} <br />
                    <Button size="small" type="link" onClick={() => previewImage(record)}>รูปภาพ</Button>
                </>;
            }
        },
        {
            title: 'สัญญา',
            dataIndex: 'premiumType',
            key: 'premiumType',
            render: (text, record, index) => {
                if (text === 'monthlyPremium') return <Tag color={'#2db7f5'} key={text}>Monthly</Tag>;
                return <Tag color={'gold'} key={text}>Yearly</Tag>
            }
        },
        {
            title: 'ประเภทสัญญา',
            dataIndex: 'contractType',
            key: 'contractType',
            render: (text, record, index) => {
                if (record.contractType === 'new') return <Tag color={'green'} key={text}>สัญญาใหม่</Tag>;
                return <Tag color={'geekblue'} key={text}>ต่ออายุ</Tag>;
            }
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onConfirmMember(record)}>ยืนยัน</Button>
                </Space>
            ),
        },
    ];

    const onConfirmMember = (member) => {
        setMemberSelected(member);
        setConfirmMemberVisible(true);
    }

    const onSaveContractsMember = async () => {
        setLoadingForm(true);
        const response = await Instance.post('approve-member/confirm/' + memberSelected.id);
        if (response.data.statusCode === 200) {
            const newMembers = members.filter((member) => member.id !== memberSelected.id);
            setMembers(newMembers);
            Notification('Approve member success', 'success');
        } else {
            Notification('Can not approve member. Please try again.', 'error');
        }
        setConfirmMemberVisible(false);
        setLoadingForm(false);
    }

    const onSearchMember = async () => {
        setFilterType('search');
        if (firstname === '' && lastname === '' && email === '') return;
        setLoading(true);
        const response = await Instance.get(`/approve-member/?${(firstname !== "" && `filter=firstName||$starts||${firstname}`)}&${(lastname !== "" && `filter=lastName||$starts||${lastname}`)}&${(email !== "" && `filter=email||$starts||${email}`)}&filter=approveStatus||$eq||waiting`);

        if (response.status === 200) {
            setMembers(response.data.data);
        } else {
            Notification('Something wrong. Please try again.', 'error');
        }
        setLoading(false);
    }

    const onReset = () => {
        setLoading(true);
        setFirstname('');
        setLastname('');
        setEmail('');
        setFilterType('search');
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }

    return (
        <>
            <Modal
                title={<Title level={5}>ลบสมาชิก</Title>}
                style={{ top: 25 }}
                visible={confirmMemberVisible}
                onOk={() => onSaveContractsMember()}
                onCancel={() => setConfirmMemberVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    {memberSelected ?
                    <Title level={5}>ต้องการยืนยันสมาชิกชื่อ {memberSelected.firstName + ' ' + memberSelected.lastName} ในประเภท "{memberSelected.contractType === 'new' ? 'สัญญาใหม่' : 'ต่อสัญญา'}" ใช่หรือไม่?</Title> : <></> }
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>ยืนยันสมาชิก</Title>
                </div>
                <Row gutter={[8, 8]}>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={firstname} placeholder="ชื่อ" onChange={(e) => setFirstname(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={lastname} placeholder="นามสกุล" onChange={(e) => setLastname(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div><Input value={email} placeholder="อีเมลล์" onChange={(e) => setEmail(e.target.value)} /></div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={4} lg={4}>
                        <div>
                            <Space>
                                <Button type="primary" onClick={() => onSearchMember()}>ค้นหา</Button>
                                <Button type="default" onClick={() => onReset()}>Reset</Button>
                                <Button type="primary" danger onClick={() => onReset()}>Refresh</Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
                {members ?
                    <Table size="small" columns={columns} dataSource={[...members]} scroll={{ x: 1200 }} pagination={{ defaultPageSize: 50, position: ['bottomRight', 'topRight'] }} /> : <></>}
                {imageSelected ?
                    <Image
                        width={200}
                        style={{ display: 'none' }}
                        src={imageSelected}
                        preview={{
                            visible: showImage,
                            src: imageSelected,
                            onVisibleChange: value => {
                                setImageSelected(null);
                                setShowImage(value);
                            },
                        }}
                    /> : <></>}
            </Spin>
        </>
    )
}

export default Approve;