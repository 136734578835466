import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Select, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";
import './social.css';

const { Title } = Typography;
const { Option } = Select;

const getSocialPosts = async () => {
    const data = await Instance.get('provide-data/getSocialPost/6');
    return data;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const Social = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [socialPosts, setSocialPosts] = useState(null);
    const [selectedSocialPost, setSelectedSocialPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);

    useEffect(() => {
        const response = getSocialPosts();
        response.then(res => {
            if (res.status !== 200) {
                setSocialPosts(null);
            } else {
                setSocialPosts(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    const onSave = () => {
        setLoadingForm(true);
        form.validateFields().then(async (value) => {
            const response = await Instance.patch('provide-data/updateSocialPost/' + selectedSocialPost.id, JSON.stringify(value));
            if (response.status === 200) {
                const index = socialPosts.findIndex((socialPost) => socialPost.id === selectedSocialPost.id);
                const newSocialPosts = socialPosts;
                newSocialPosts[index] = value;
                setSocialPosts(newSocialPosts);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            return;
        });
    };

    const onEdit = (selectedSocialPost) => {
        form.setFieldsValue({ title: selectedSocialPost.title, socialType: selectedSocialPost.socialType, link: selectedSocialPost.link, description: selectedSocialPost.description })
        setSelectedSocialPost(selectedSocialPost);
        setVisible(true);
    }

    return (
        <>
            <Modal
                title="Update data"
                style={{ top: 25 }}
                visible={visible}
                onOk={() => onSave()}
                onCancel={() => setVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={form}>
                        <Form.Item
                            name="title"
                            label="Title"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill title'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="socialType" label="Social Type" rules={[{ required: true }]}>
                            <Select
                                placeholder="Select a Social Type"
                                // onChange={onGenderChange}
                                // allowClear
                            >
                                <Option value="web">web</Option>
                                <Option value="facebook">facebook</Option>
                                <Option value="twitter">twitter</Option>
                                <Option value="youtube">youtube</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="link"
                            label="Link"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill link'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill description'
                                },
                            ]}
                        >
                            <Input.TextArea rows={5} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการบทความข่าวสาร</Title>
                    <Row gutter={[8, 8]}>
                        {
                            socialPosts ? socialPosts.map((socialPost, idx) => {
                                return <Col key={socialPost.id} span={32} xs={32} sm={32} md={8} lg={8}>
                                    <Card
                                        bodyStyle={{ height: 150 }}
                                        key={socialPost.id}
                                        title={<b>{socialPost.title}</b>}
                                        bordered={true}
                                        actions={[
                                            <Button type="primary" block onClick={() => onEdit(socialPost)}><EditOutlined key="edit" /></Button>
                                        ]}
                                    >
                                        <p>{socialPost.description}</p>
                                    </Card>
                                </Col>
                            }) : <></>
                        }
                    </Row>
                </div>
            </Spin>
        </>
    );
}

export default Social;