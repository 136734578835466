import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from '../../service/service';

const { Title } = Typography;

const getBox = async () => {
    const data = await Instance.get('provide-data/getAllBoxs');
    return data;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const Box = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [boxs, setBoxs] = useState(null);
    const [selectedBox, setSelectedBox] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);

    useEffect(() => {
        const response = getBox();
        response.then(data => {
            if (data.status !== 200) {
                setBoxs(null);
            } else {
                setBoxs(data.data.boxs);
            }
            setLoading(false);
        })
    }, []);

    const onSave = () => {
        setLoadingForm(true);
        form.validateFields().then(async (value) => {
            value.seq = selectedBox.seq;
            const response = await Instance.post('provide-data/updateBox', JSON.stringify(value));
            if (response.status === 200) {
                const index = boxs.findIndex((box) => box.seq === value.seq);
                const newBoxs = boxs;
                newBoxs[index] = value;
                setBoxs(newBoxs);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            return;
        });
    };

    const onEdit = (selectedBox) => {
        form.setFieldsValue({ title: selectedBox.title, data: selectedBox.data, description: selectedBox.description })
        setSelectedBox(selectedBox);
        setVisible(true);
    }

    return (
        <>
            <Modal
                title="Update data"
                style={{ top: 25 }}
                visible={visible}
                onOk={() => onSave()}
                onCancel={() => setVisible(false)}
                width={1000}

            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={form}>
                        <Form.Item
                            name="title"
                            label="Title"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill title'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="data"
                            label="Data"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill data'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill description'
                                },
                            ]}
                        >
                            <Input.TextArea rows={5} />
                        </Form.Item>
                    </Form>
                </Spin>

            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการ BOX</Title>
                    <Row gutter={[8, 8]}>
                        {
                            boxs ? boxs.map(box => {
                                return <Col key={box.seq} span={32} xs={32} sm={32} md={6} lg={6}>
                                    <Card
                                        bodyStyle={{ height: 100 }}
                                        key={box.seq}
                                        title={box.title}
                                        bordered={true}
                                        actions={[
                                            <Button type="primary" block onClick={() => onEdit(box)}><EditOutlined key="edit" /></Button>
                                        ]}
                                    >
                                        <p>{box.data}</p>
                                    </Card>
                                </Col>
                            }) : <></>
                        }
                    </Row>
                </div>
            </Spin>
        </>
    )
}

export default Box;