import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import './index.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Signin from './pages/Signin/Signin';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/signin" component={Signin} />
        </Switch>
      </HashRouter>
    )
  }
}

export default App;