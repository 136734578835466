import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Space, Spin, Table, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from '../../service/service';

const { Title } = Typography;

const getInbox = async () => {
    const data = await Instance.get('inbox?sort=id,DESC');
    return data;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const onCustomColumn = (onEdit, onDelete) => {
    const columns = [
        {
            title: 'NO',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 70,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'หัวข้อ',
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
            // width: 150,
        },
        {
            title: 'หัวข้อแสดงย่อย',
            dataIndex: 'subtitle',
            key: 'subtitle',
            // width: 150,
        },
        {
            title: 'ข้อมูล',
            dataIndex: 'description',
            key: 'description',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => {
                return <>
                    <Tooltip placement="top" title={text}>
                        {text}
                    </Tooltip>
                </>
            }
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEdit(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onDelete(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    return columns;

}

const Inbox = () => {
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [addVisible, setAddVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [inboxs, setInboxs] = useState(null);
    const [selectedInbox, setSelectedInbox] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addLoadingForm, setAddLoadingForm] = useState(false);
    const [editLoadingForm, setEditLoadingForm] = useState(false);

    useEffect(() => {
        const response = getInbox();
        response.then(data => {
            if (data.status !== 200) {
                setInboxs(null);
            } else {
                setInboxs(data.data.data);
            }
            setLoading(false);
        })
    }, []);

    const onEditSave = () => {
        setEditLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const response = await Instance.patch('inbox/' + selectedInbox.id, JSON.stringify(value));
            if (response.status === 200) {
                const index = inboxs.findIndex((inbox) => inbox.id === selectedInbox.id);
                const newInboxs = inboxs;
                newInboxs[index] = { id: selectedInbox.id, ...value };
                setInboxs(newInboxs);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setEditVisible(false);
            setEditLoadingForm(false);
        }).catch((reason) => {
            return;
        });
    };

    const onEdit = (selectedInbox) => {
        formEdit.setFieldsValue({ title: selectedInbox.title, subtitle: selectedInbox.subtitle, description: selectedInbox.description })
        setSelectedInbox(selectedInbox);
        setEditVisible(true);
    }

    const onDelete = (selectedInbox) => {
        // setSelectedInbox(selectedInbox);
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบข้อมูลใช่หรือไม่?',
            okText: 'ลบ',
            cancelText: 'ยกเลิก',
            onOk: async () => onConfirmDelete(selectedInbox)
        });
    }

    const onConfirmDelete = async (selectedInbox) => {
        setLoading(true);
        const response = await Instance.delete('inbox/' + selectedInbox.id);
        if (response.status === 200) {
            const newInboxs = inboxs.filter(v => v.id !== selectedInbox.id);
            setInboxs(newInboxs);
            Notification('Delete success', 'success');
        } else {
            Notification('Something was wrong, Please try again.', 'success');
        }
        setLoading(false);
    }

    const onSave = async () => {
        formAdd.validateFields().then(async (value) => {
            setAddLoadingForm(true);
            const response = await Instance.post('inbox', JSON.stringify(value));
            if (response.status === 201) {
                const getNewInboxResponse = await getInbox();
                if (getNewInboxResponse.status === 200) {
                    setInboxs(getNewInboxResponse.data.data);
                } else {
                    setInboxs(null);
                }
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setAddVisible(false);
            setAddLoadingForm(false);
        }).catch((reason) => {
            setAddLoadingForm(false);
            return;
        });
    }

    return (
        <>
            <Modal
                title="Add data"
                style={{ top: 25 }}
                visible={addVisible}
                onOk={() => onSave()}
                onCancel={() => setAddVisible(false)}
                width={1000}
            >
                <Spin spinning={addLoadingForm}>
                    <Form {...layout} form={formAdd}>
                        <Form.Item
                            name="title"
                            label="หัวข้อ"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill title'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="subtitle"
                            label="หัวข้อแสดงย่อย"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill subtitle'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="ข้อมูล HTML"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill description'
                                },
                            ]}
                        >
                            <Input.TextArea rows={10} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Update data"
                style={{ top: 25 }}
                visible={editVisible}
                onOk={() => onEditSave()}
                onCancel={() => setEditVisible(false)}
                width={1000}
            >
                <Spin spinning={editLoadingForm}>
                    <Form {...layout} form={formEdit}>
                        <Form.Item
                            name="title"
                            label="หัวข้อ"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill title'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="subtitle"
                            label="หัวข้อแสดงย่อย"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill subtitle'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="ข้อมูล HTML"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill description'
                                },
                            ]}
                        >
                            <Input.TextArea rows={10} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการกล่องข้อความ</Title>
                    <Divider style={{ padding: '5px 5px' }} />
                    <Button type="primary" onClick={() => { formAdd.resetFields(); setAddVisible(true); }}>เพิ่มกล่องข้อความ</Button>
                    <Table style={{ marginTop: 5 }} size="small" columns={onCustomColumn(onEdit, onDelete)} dataSource={inboxs ? [...inboxs] : []} />
                </div>
            </Spin>
        </>
    )
}

export default Inbox;