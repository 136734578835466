import { BoxPlotFilled, CheckOutlined, ContainerOutlined, FileDoneOutlined, GlobalOutlined, LineChartOutlined, PieChartOutlined, StockOutlined, TagsOutlined, UserOutlined } from "@ant-design/icons";
import Ad from "./pages/Advertisement/Ad";
import Approve from "./pages/Approve/Approve";
import Box from "./pages/Box/Box";
import Inbox from "./pages/Inbox/Inbox";
import Member from "./pages/Member/Member";
import News from "./pages/News/News";
import Realyield from "./pages/Realyield/Realyield";
import Social from "./pages/Social/Social";
import SupportAndResistance from "./pages/SupportAndResistance/SupportAndResistance";
import Weighting from "./pages/Weighting/Weighting";

const routes = [
  {
    path: '/',
    exact: true,
    name: 'member',
    nameTH: 'จัดการสมาชิก',
    icon: <UserOutlined />,
    component: Member,
  },
  {
    path: '/approve',
    exact: true,
    name: 'approve',
    nameTH: 'ยืนยันสมาชิก',
    icon: <CheckOutlined />,
    component: Approve,
  },
  {
    path: '/box',
    exact: true,
    name: 'box',
    nameTH: 'จัดการ box',
    icon: <BoxPlotFilled />,
    component: Box,
  },
  {
    path: '/weighting',
    exact: true,
    name: 'box',
    nameTH: 'จัดการ weighting',
    icon: <PieChartOutlined />,
    component: Weighting,
  },
  {
    path: '/sandp',
    exact: true,
    name: 'support-and-resistance',
    nameTH: 'จัดการแนวรับแนวต้าน',
    icon: <StockOutlined />,
    component: SupportAndResistance,
  },
  {
    path: '/news',
    exact: true,
    name: 'news',
    nameTH: 'จัดการแผนการเทรด',
    icon: <GlobalOutlined />,
    component: News,
  },
  {
    path: '/social',
    exact: true,
    name: 'social',
    nameTH: 'จัดการบทความข่าวสาร',
    icon: <FileDoneOutlined />,
    component: Social,
  },
  {
    path: '/ad',
    exact: true,
    name: 'ad',
    nameTH: 'จัดการโฆษณา Popup',
    icon: <TagsOutlined />,
    component: Ad,
  },
  {
    path: '/inbox',
    exact: true,
    name: 'inbox',
    nameTH: 'จัดการกล่องข้อความ',
    icon: <ContainerOutlined />,
    component: Inbox,
  },
  {
    path: '/realyield',
    exact: true,
    name: 'realyield',
    nameTH: 'Link Realyield',
    icon: <LineChartOutlined />,
    component: Realyield,
  },
]

export default routes;