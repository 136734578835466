import { Button, Form, Input, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Instance from "../../service/service";

const { Title } = Typography;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 8 },
};

const getSupportAndResistance = async () => {
    const data = await Instance.get('provide-data/getSupportAndResistance');
    return data;
}


const SupportAndResistance = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const response = getSupportAndResistance();
        response.then(res => {
            if (res.status !== 200) {
                setData(null);
            } else {
                setData(res.data);
            }
            setLoading(false);
        })
    }, [])

    const reset = () => {
        form.setFieldsValue(data);
    }

    const onSave = () => {
        setLoading(true);
        form.validateFields().then(async (value) => {
            const response = await Instance.post('provide-data/updateSupportAndResistance', JSON.stringify(value));
            if (response.status === 200) {
                setData(value);
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update date. Please try again.', 'error');
            }
            setLoading(false);
        }).catch((reason) => { return; })
    }

    return (
        <>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการแนวรับแนวต้าน</Title>
                </div>
                {
                    data ?
                        <Form {...layout} form={form}>
                            <Form.Item
                                name="resistance3"
                                label="Resistance 3"
                                initialValue={data.resistance3}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Resistance 3'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="resistance2"
                                label="Resistance 2"
                                initialValue={data.resistance2}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Resistance 2'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="resistance1"
                                label="Resistance 1"
                                initialValue={data.resistance1}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Resistance 1'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="support1"
                                label="Support 1"
                                initialValue={data.support1}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Support 1'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="support2"
                                label="Support 2"
                                initialValue={data.support2}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Support 2'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="support3"
                                label="Support 3"
                                initialValue={data.support3}
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill Support 3'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Space>
                                    <Button type="primary" onClick={() => onSave()}>
                                        Save
                                    </Button>
                                    <Button type="default" onClick={() => reset()}>
                                        Reset
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                        : <></>}
            </Spin>
        </>
    )
}

export default SupportAndResistance;