import React, { useState, useEffect } from 'react';
import { Button, Image, Spin, Typography, Upload } from "antd";
import domain from '../../service/config';
import { InboxOutlined } from '@ant-design/icons';
import Notification from '../../components/Notification';
import Instance from '../../service/service';

const { Title } = Typography;
const { Dragger } = Upload;

const adImage = domain + "provide-data/getAdImage";

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject('Error: ', error);
        };
    })
}

const Ad = () => {
    const [loading, setLoading] = useState(false);
    const [defaultImage, setDefaultImage] = useState(adImage);
    const [fileList, setFileList] = useState([]);
    const [imageSelected, setImageSelected] = useState(null);


    const onUpload = async () => {

        if (fileList.length === 0) {
            Notification('กรุณาอัพโหลดรูปภาพ', 'error');
            return;
        }

        if (fileList[0].size >= 2097152) {
            Notification('กรุณาอัพโหลดรูปภาพที่ไม่เกิน 2MB', 'error');
            return;
        }

        setLoading(true);

        const base64 = await getBase64(fileList[0]);
        try {
            const response = await Instance.post('provide-data/updateAdImage', JSON.stringify({ image: base64 }), { timeout: 1000 * 30, headers: { 'Content-Type': 'application/json' } });
            if (response.status === 200) {
                Notification('Update Ad Image success', 'success');
            } else {
                Notification('Can not update. Please try again.', 'error');
            }
        } catch (error) {
            Notification("เกิดข้อพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง", "error");
        }
        setImageSelected(null);
        setFileList([]);
        setLoading(false);
    }

    const onReset = () => {
        setImageSelected(null);
        setFileList([]);
    }

    return (
        <Spin spinning={loading}>
            <Title level={4}>Popup</Title>
            {imageSelected ? <></> :
                <div style={{ textAlign: 'center' }}>
                    <Image src={defaultImage} width={350} height={183} />
                </div>}
            <Dragger
                style={{ marginBottom: 10, marginTop: 10 }}
                accept=".png,.jpg,jpeg"
                fileList={fileList}
                multiple={false}
                showUploadList={false}
                onChange={(e) => {
                    const file = e.file;
                    const imageEle = document.getElementById('preview');
                    imageEle.src = URL.createObjectURL(file);
                    setImageSelected(URL.createObjectURL(file));
                }}
                beforeUpload={(e) => {
                    const temp = [e];
                    setFileList(temp);
                    return false;
                }}
                onDrop={(e) => {
                    console.log('Dropped files', e.dataTransfer.files);
                }}

            >
                {fileList.length === 0 ?
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">คลิกที่นี่เพื่ออัพโหลดรูปภาพ</p>
                    </> : <><img style={{ width: 350, height: 183 }} className="preview" id="preview" /></>}
            </Dragger>
            <div style={{ textAlign: 'center', padding: 5, margin: 5 }}>
                <Button type="primary" onClick={() => onUpload()}>อัพโหลด</Button> <Button type="default" danger onClick={() => onReset()}>reset</Button>
            </div>
        </Spin>
    )
};

export default Ad;