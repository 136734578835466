import React, { Component } from 'react';
import './signin.css'
import { Form, Input, Button, Row, Card, Spin } from 'antd';
import { HomeOutlined, UserOutlined, LockOutlined } from '@ant-design/icons'
import Instance from '../../service/service';
import Notification from '../../components/Notification';

class Signin extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        if (localStorage.getItem('token') != null) {
            this.props.history.push('/');
        }

        this.state = {
            loading: false,
        }
    }

    onFinish = async (values) => {
        this.setState({
            loading: true
        });

        try {
            const response = await Instance.post('auth/login', JSON.stringify(values));
            this.setState({
                loading: false
            });
            console.log(response.data);
            if (response.status === 200) {
                if (response.data.role === 'admin') {
                    const profile = {
                        firstName: response.data.firstname,
                        lastName: response.data.lastname,
                        id: response.data.id
                    };

                    localStorage.setItem('profile', JSON.stringify(profile));
                    localStorage.setItem('token', response.data.token);

                    this.props.history.push('/');
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }

        } catch (error) {
            Notification('Email or password wrong. Please try again.', 'error');
            this.formRef.current.resetFields();
            this.setState({
                loading: false
            });
        }
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row justify="center" className="bg">
                    <Form
                        ref={this.formRef}
                        style={{ minWidth: 350 }}
                        className="card"
                        name="signin"
                        onFinish={this.onFinish}
                    >
                        <Card>
                            <div className="header-login">
                                <h4>Signin @forexmonday</h4>
                                <div className="icon-line">
                                    <a href="https://forexmonday-dashboard.com/" target="_blank">
                                        <HomeOutlined style={{ fontSize: '35px', color: '#6F7DBE' }} />
                                    </a>
                                </div>
                            </div>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาใส่ชื่อผู้ใช้',
                                    },
                                ]}
                            >
                                <Input maxLength={32} prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="อีเมลล์" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาใส่รหัสผ่าน',
                                    },
                                ]}
                            >
                                <Input maxLength={32} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="รหัสผ่าน" />
                            </Form.Item>

                            <div className="footer">
                                <Button block={true} className="btn" htmlType={'submit'}>
                                    Signin
                                </Button>
                            </div>
                        </Card>
                    </Form>
                </Row>
            </Spin>
        )
    }
}

export default Signin;